import { Box, Typography } from "@mui/material";
import React from "react";
import bgImgAbout from "../assets/Aboutusbg.jpg";

const Aboutus = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "30px",
        mb: "50px",
      }}>
      <Box
        sx={{
          borderRadius: "0 0 240px 50%/35px",
          position: "relative",
          height: "80vh",
          width: "100%",
          overflow: "hidden",
        }}>
        <img
          src={bgImgAbout}
          alt="Background Image"
          style={{
            borderRadius: "0 0 240px 50%/35px",
            objectFit: "cover",
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        />
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(45, 97, 106, 0.8)",
          }}
        />
        <Typography
          sx={{
            position: "absolute",
            color: "#fff",
            fontSize: "3rem",
            fontWeight: 700,
            fontFamily: "Pacifico",
            top: "40vh",
            left: "20px",
            textAlign: "left",
          }}>
          About Us
        </Typography>
      </Box>
      <Typography
        sx={{
          padding: "20px",
          color: "#1A525D",
          fontFamily: "Pacifico",
          fontSize: "30px",
        }}>
        Welcome to drharshalpediatrician, where we are dedicated to providing
        the highest quality healthcare for your child. Our team of experienced
        and compassionate pediatricians is committed to the well-being and
        development of your little ones.
      </Typography>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xl: "repeat(4,1fr)",
            lg: "repeat(4,1fr)",
            md: "repeat(4,1fr)",
            sm: "repeat(1,1fr)",
            xs: "repeat(1,1fr)",
          },
          justifyContent: "space-between",
          padding: "20px",
          alignItems: "center",
        }}>
        <Box
          sx={{
            width: "auto",
            height: {
              xl: "100%",
              lg: "100%",
              md: "100%",
              sm: "auto",
              xs: "auto",
            },
            m: "10px",
            padding: "20px",
            backgroundColor: "#FE6B6A",
            boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px",
            borderRadius: "10px",
          }}>
          <Typography
            sx={{
              color: "#F1DF6D",
              fontWeight: 700,
              fontFamily: "Pacifico",
              mb: "10px",
            }}>
            Expertise You Can Trust
          </Typography>
          <Typography
            sx={{
              color: "white",
            }}>
            Our pediatricians are board-certified and have years of experience
            in caring for children from infancy through adolescence. We stay
            current with the latest advances in pediatric medicine to ensure
            that your child receives the best possible care.
          </Typography>
        </Box>
        <Box
          sx={{
            width: "auto",
            height: {
              xl: "100%",
              lg: "100%",
              md: "100%",
              sm: "auto",
              xs: "auto",
            },
            m: "10px",

            padding: "20px",
            backgroundColor: "#FE6B6A",
            boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px",
            borderRadius: "10px",
          }}>
          <Typography
            sx={{
              color: "#F1DF6D",
              fontWeight: 700,
              fontFamily: "Pacifico",
              mb: "10px",
            }}>
            Family-Centered Care
          </Typography>
          <Typography
            sx={{
              color: "white",
            }}>
            We understand that a child's health is closely tied to their
            family's well-being. That's why we emphasize open communication and
            partnership with parents and caregivers. We are here to answer your
            questions, address your concerns, and provide guidance every step of
            the way.
          </Typography>
        </Box>
        <Box
          sx={{
            width: "auto",
            height: {
              xl: "100%",
              lg: "100%",
              md: "100%",
              sm: "auto",
              xs: "auto",
            },
            m: "10px",
            padding: "20px",
            backgroundColor: "#FE6B6A",
            boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px",
            borderRadius: "10px",
          }}>
          <Typography
            sx={{
              color: "#F1DF6D",
              fontWeight: 700,
              fontFamily: "Pacifico",
              mb: "10px",
            }}>
            Community Involvement
          </Typography>
          <Typography
            sx={{
              color: "white",
            }}>
            We are proud to be a part of this community, and we believe in
            giving back. drharshalpediatrician actively participates in local
            initiatives aimed at improving children's health and well-being.
          </Typography>
        </Box>
        <Box
          sx={{
            width: "auto",
            height: {
              xl: "100%",
              lg: "100%",
              md: "100%",
              sm: "auto",
              xs: "auto",
            },
            m: "10px",
            padding: "20px",
            backgroundColor: "#FE6B6A",
            boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px",
            borderRadius: "10px",
          }}>
          <Typography
            sx={{
              color: "#F1DF6D",
              fontWeight: 700,
              fontFamily: "Pacifico",
              mb: "10px",
            }}>
            Schedule Your Child's Online consultation
          </Typography>
          <Typography
            sx={{
              color: "white",
            }}>
            We look forward to becoming your trusted partner in your child's
            healthcare journey. Schedule an online Consultation appointment with
            us today and experience the difference of personalized pediatric
            care at drharshalpediatrician
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Aboutus;
