import { Box, Typography } from "@mui/material";
import React from "react";
import ProgressBar from "./ProgressBar";
import blogImg from "../assets/blobImage.jpg";

const Section2 = ({ animateProgressBar }) => {
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          padding: "30px",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <Box
          sx={{
            width: { xl: "48%", lg: "48%", md: "48%", sm: "100%", xs: "100%" },
            textAlign: "left",
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}>
          <Typography
            sx={{
              fontFamily: "Pacifico",
              color: "#FE6B6A",
            }}>
            Detailed Report
          </Typography>
          <Typography
            sx={{
              fontFamily: "Pacifico",
              color: "#1A525D",
              fontSize: {
                xl: "41px",
                lg: "41px",
                md: "41px",
                sm: "30px",
                xs: "30px",
              },
            }}>
            We Are A Clinic Specializing In Child Care
          </Typography>
          <Typography
            sx={{
              color: "#888A88",
            }}>
            At our dedicated clinic specializing in child care, we prioritize
            the well-being and healthy development of your little ones. Our team
            of compassionate healthcare professionals is committed to providing
            comprehensive and specialized care for children of all ages, from
            infants to adolescents.
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}>
            <Typography sx={{ fontFamily: "Pacifico", color: "#1A525D" }}>
              Quick Service
            </Typography>
            <ProgressBar
              progress={80}
              bgColor="#4ECCC5"
              animateOnUpdate={animateProgressBar}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}>
            <Typography sx={{ fontFamily: "Pacifico", color: "#1A525D" }}>
              Qualified Professionals
            </Typography>
            <ProgressBar
              progress={95}
              bgColor="#FE6B6A"
              animateOnUpdate={animateProgressBar}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: "40%",
            position: "relative",
            display: {
              xl: "block",
              lg: "block",
              md: "block",
              sm: "none",
              xs: "none",
            },
          }}>
          <img
            src={blogImg}
            alt="blob"
            style={{
              width: "300px",
              height: "300px",
              borderRadius: "50%",
              position: "absolute",
              top: "30%",
              left: "5%",
            }}
          />
          <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="#FF0066"
              d="M36.3,-38.4C44.9,-27.7,48.3,-13.8,50.4,2.1C52.5,18,53.3,36,44.7,48.8C36,61.5,18,68.9,-2,70.9C-22,72.9,-44.1,69.5,-59.8,56.8C-75.5,44.1,-84.9,22,-81,3.9C-77.1,-14.3,-60,-28.6,-44.3,-39.3C-28.6,-50,-14.3,-57.2,-0.2,-56.9C13.8,-56.7,27.7,-49.1,36.3,-38.4Z"
              transform="translate(100 100)"
            />
          </svg>
        </Box>
      </Box>
    </div>
  );
};

export default Section2;
