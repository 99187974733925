import { Box, Typography } from "@mui/material";
import React from "react";
import bgImgFooter from "../assets/baby_and_doc-n.jpg";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import logo from "../assets/logo.png";
import { Link } from "react-router-dom";

const Footer = () => {
  const socialIcons = {
    fontSize: "15px",
    backgroundColor: "#FE6A6B",
    padding: "6px",
    borderRadius: "50%",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#FEE76D",
      color: "#1B535D",
    },
  };

  const handleclick = (link) => {
    window.open(link, "_blank");
  };
  return (
    <Box
      sx={{
        backgroundImage: `linear-gradient(rgba(27, 83, 93, 0.9), rgba(27, 83, 93, 0.9)), url(${bgImgFooter})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "auto",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          flexDirection: {
            xl: "row",
            lg: "row",
            md: "row",
            sm: "column",
            xs: "column",
          },
          gap: "20px",
          borderBottom: "1px solid white",
          margin: {
            xl: "90px",
            lg: "90px",
            md: "90px",
            sm: "10px",
            xs: "10px",
          },
          paddingTop: {
            xl: "50px",
            lg: "50px",
            md: "50px",
            sm: "0px",
            xs: "0px",
          },
          paddingBottom: "20px",
        }}>
        <Box
          sx={{
            width: {
              xl: "48%",
              lg: "48%",
              md: "48%",
              sm: "90%",
              xs: "90%",
            },
            borderRight: {
              xl: "1px solid white",
              lg: "1px solid white",
              md: "1px solid white",
              sm: "none",
              xs: "none",
            },
            textAlign: "left",
            padding: {
              xl: "20px",
              lg: "20px",
              md: "20px",
              sm: "0px",
              xs: "0px",
            },
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            m: "auto",
          }}>
          <Box
            sx={{
              ml: "10px",
              width: "150px",
              height: "90px",
            }}>
            <img
              src={logo}
              alt="logo"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "fill",
              }}
            />
          </Box>
          <Typography
            sx={{
              color: "white",
              mt: "20px",
              textAlign: "left",
            }}>
            Your child's health is precious, and we provide expert care tailored
            to their unique needs. Our dedicated professionals ensure their
            well-being, happiness, and growth, creating a foundation for
            lifelong vitality
          </Typography>
          <Typography
            sx={{
              color: "white",
              mt: "5px",
              textAlign: "left",
            }}>
            Address: NAVJEEVAN HOSPITAL, BAMBRUD (RANICHE) , Tal-Pachora,Dist
            -jalgaon, Maharashtra
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              color: "white",
            }}>
            <FacebookIcon
              sx={socialIcons}
              onClick={() =>
                handleclick(
                  "https://m.facebook.com/profile.php/?id=100003089917869&name=xhp_nt__fb__action__open_user#_=_"
                )
              }
            />
            <InstagramIcon
              sx={socialIcons}
              onClick={() =>
                handleclick("https://www.instagram.com/drharshalpediatrician")
              }
            />
            <YouTubeIcon
              sx={socialIcons}
              onClick={() =>
                handleclick("https://www.youtube.com/@apladoctor5310")
              }
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: {
              xl: "48%",
              lg: "48%",
              md: "48%",
              sm: "90%",
              xs: "90%",
            },
            textAlign: "left",
            padding: {
              xl: "20px",
              lg: "20px",
              md: "20px",
              sm: "0px",
              xs: "0px",
            },
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            m: "auto",
          }}>
          <Typography
            sx={{
              color: "white",
              fontFamily: "Pacifico",
            }}>
            Specialties
          </Typography>
          <Box
            sx={{
              color: "white",
            }}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(2,1fr)",
                width: "100%",
                gap: "10px",
              }}>
              {[
                "Nutrition",
                "Behaviour",
                "Immunizations",
                "Infections",
                "Growth",
                "Injuries",
              ].map((el, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    justifySelf: "start",
                    gap: "10px",
                    "&:hover": {
                      color: "#FEE76D",
                    },
                  }}>
                  <Box
                    sx={{
                      width: "8px",
                      height: "8px",
                      borderRadius: "50%",
                      border: "1.5px solid ",
                    }}></Box>
                  <Typography>{el}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
      <a
        href="https://drharshalpediatrician.com"
        target="_blank"
        style={{
          textDecoration: "none",
          color: "white",
          paddingBottom: "20px",
        }}>
        {" "}
        All Rights Reserved ©2023 – www.drharshalpediatrician.com
      </a>
    </Box>
  );
};

export default Footer;
