import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";

const ProgressBar = ({ progress, bgColor, animateOnUpdate }) => {
  const [innerWidth, setInnerWidth] = useState(0);

  useEffect(() => {
    if (animateOnUpdate) {
      const animationDuration = 300;
      const interval = 10;
      const steps = (progress * animationDuration) / 100;
      let currentStep = 0;

      const intervalId = setInterval(() => {
        if (currentStep < steps) {
          setInnerWidth((currentStep / steps) * progress);
          currentStep++;
        } else {
          setInnerWidth(progress);
          clearInterval(intervalId);
        }
      }, interval);

      return () => {
        clearInterval(intervalId);
      };
    } else {
      setInnerWidth(progress);
    }
  }, [progress, animateOnUpdate]);

  return (
    <div>
      <Box
        sx={{
          height: "15px",
          backgroundColor: "#FEE76D",
          width: "100%",
          borderRadius: "2px",
        }}>
        <Box
          sx={{
            width: `${innerWidth}%`, // Use the state variable here
            height: "100%",
            backgroundColor: bgColor,
            borderRadius: "2px",
            transition: "width 0.5s ease", // CSS transition
          }}>
          <Typography
            sx={{
              textAlign: "right",
              fontSize: "11px",
              fontFamily: "Pacifico",
              color: "white",
              mr: "10px",
            }}>
            {Math.round(innerWidth)}% {/* Display rounded progress */}
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default ProgressBar;
