import { Box, Typography } from "@mui/material";
import React from "react";
import mission from "../assets/mission.png";
import vision from "../assets/vision.png";
const Section5 = () => {
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          flexDirection: {
            xl: "row",
            lg: "row",
            md: "row",
            sm: "column",
            xs: "column",
          },
          gap: "20px",
          margin: "auto",
          mt: "15px",
          pb: "15px",
          width: { xl: "100%", lg: "100%", md: "100%", sm: "90%", xs: "90%" },
        }}>
        <Box
          sx={{
            width: { xl: "48%", lg: "48%", md: "48%", sm: "100%", xs: "100%" },
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            borderRadius: "10px",
          }}>
          <Typography
            sx={{
              fontFamily: "Pacifico",
              color: "#1A525D",
              fontSize: {
                xl: "41px",
                lg: "41px",
                md: "41px",
                sm: "30px",
                xs: "30px",
              },
              display: "flex",
              alignItems: "center",
              gap: "15px",
              justifyContent: "center",
              padding: "20px 0px",
            }}>
            Our Mission
            <img
              src={mission}
              alt="mission"
              style={{ width: "50px", height: "50px" }}
            />
          </Typography>
          <Typography
            sx={{
              color: "#888A88",
              textAlign: "center",
              padding: "0px 20px 20px 20px",
            }}>
            At drharshalpediatrician Pediatric Care, our mission is to provide
            compassionate and comprehensive healthcare for your child, from
            infancy through adolescence. We are dedicated to fostering a
            nurturing and trusted environment where your child's well-being is
            our top priority. Our experienced team of pediatric professionals is
            committed to delivering the highest standard of care, focusing on
            preventive medicine, early intervention, and the overall health and
            happiness of your little ones. We aim to partner with parents and
            families, empowering them with knowledge and support to ensure the
            best start in life for their children. Driven by our core values of
            empathy, integrity, and excellence, we strive to be your trusted
            partners in your child's health journey. Your child's smile is our
            greatest reward, and we're here to ensure it shines
            brightly every day.
          </Typography>
        </Box>
        <Box
          sx={{
            width: { xl: "48%", lg: "48%", md: "48%", sm: "100%", xs: "100%" },
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            borderRadius: "10px",
          }}>
          <Typography
            sx={{
              fontFamily: "Pacifico",
              color: "#1A525D",
              fontSize: {
                xl: "41px",
                lg: "41px",
                md: "41px",
                sm: "30px",
                xs: "30px",
              },
              display: "flex",
              alignItems: "center",
              gap: "15px",
              justifyContent: "center",
              padding: "20px 0px",
            }}>
            Our Vision
            <img
              src={vision}
              alt="vision"
              style={{ width: "50px", height: "50px" }}
            />
          </Typography>
          <Typography
            sx={{
              color: "#888A88",
              textAlign: "center",
              padding: "0px 20px 20px 20px",
            }}>
            Our vision at drharshalpediatrician Pediatric Care is to be the
            leading beacon of pediatric excellence in our community and beyond.
            We aspire to create a healthier, happier, and thriving future for
            every child we serve. We envision a world where children grow up
            with optimal health, well-equipped to embrace life's opportunities.
            To achieve this, we are dedicated to continually advancing our
            medical expertise, leveraging cutting-edge technology, and staying
            at the forefront of pediatric research and innovation. We aim to be
            the trusted source of guidance and support for parents, ensuring
            they have the resources and knowledge needed to nurture their
            child's development. Together, with our community, we envision a
            brighter tomorrow where every child's potential is realized, and
            their dreams are boundless
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default Section5;
