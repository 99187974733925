import React, { useState } from "react";
import logo from "../assets/logo.png";
import { Box, Drawer, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useNavigate } from "react-router-dom";

const MobileNav = ({
  scrollToContact,
  setTestiScroll,
  scrollToTestimonial,
  setContactScroll,
}) => {
  const navigate = useNavigate();
  const navLinkStyles = {
    padding: "20px",
    borderBottom: "1px solid lightgrey",
    color: "black",
  };

  const socialIcons = {
    fontSize: "15px",
    backgroundColor: "#FE6A6B",
    padding: "6px",
    borderRadius: "50%",
    color: "white",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#FEE76D",
      color: "#1B535D",
    },
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleclick = (link) => {
    window.open(link, "_blank");
  };
  const HandleTesti = () => {
    setTestiScroll(true);
    scrollToTestimonial();
    setIsOpen(!isOpen);
    navigate("/");
  };

  const HandleContact = () => {
    setContactScroll(true);
    scrollToContact();
    setIsOpen(!isOpen);
    navigate("/");
  };
  return (
    <div>
      <Box
        sx={{
          display: {
            xl: "none",
            lg: "none",
            md: "none",
            sm: "flex",
            xs: "flex",
          },
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
          color: "white",
          background: "transparent",
          position: "absolute",
          zIndex: 9999999,
          mt: "10px",
        }}>
        <Link to="/">
          <Box
            sx={{
              ml: "10px",
              width: "120px",
              height: "70px",
            }}>
            <img
              src={logo}
              alt="logo"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "fill",
              }}
            />
          </Box>
        </Link>
        <MenuIcon sx={{ mr: "10px", fontSize: "30px" }} onClick={toggleOpen} />
        <Drawer
          open={isOpen}
          onClose={toggleOpen}
          anchor="left"
          sx={{
            zIndex: 99999999,
          }}>
          <Box
            sx={{
              width: 250,
              padding: "20px",
            }}>
            <Box
              onClick={toggleOpen}
              sx={{
                textAlign: "right",
              }}>
              <CloseIcon />
            </Box>
            <Link
              to="/"
              style={{
                textDecoration: "none",
              }}
              onClick={() => setIsOpen(!isOpen)}>
              <Typography sx={navLinkStyles}>Home</Typography>
            </Link>

            <Link
              to="/about-us"
              style={{
                textDecoration: "none",
              }}
              onClick={() => setIsOpen(!isOpen)}>
              <Typography sx={navLinkStyles}>About Us</Typography>
            </Link>
            <Typography sx={navLinkStyles} onClick={HandleTesti}>
              Testimonials
            </Typography>
            <Link
              to="/blogs"
              style={{
                textDecoration: "none",
              }}
              onClick={() => setIsOpen(!isOpen)}>
              <Typography sx={navLinkStyles}>Parenting Blog's</Typography>
            </Link>

            <Typography sx={navLinkStyles} onClick={HandleContact}>
              Contact Us
            </Typography>
            <Box
              sx={{
                padding: "20px",
                borderBottom: "1px solid lightgrey",
              }}>
              <Typography mb="10px">Follow us on</Typography>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}>
                <FacebookIcon
                  sx={socialIcons}
                  onClick={() =>
                    handleclick(
                      "https://m.facebook.com/profile.php/?id=100003089917869&name=xhp_nt__fb__action__open_user#_=_"
                    )
                  }
                />
                <InstagramIcon
                  sx={socialIcons}
                  onClick={() =>
                    handleclick(
                      "https://www.instagram.com/drharshalpediatrician"
                    )
                  }
                />
                <YouTubeIcon
                  sx={socialIcons}
                  onClick={() =>
                    handleclick("https://www.youtube.com/@apladoctor5310")
                  }
                />
              </Box>
            </Box>
          </Box>
        </Drawer>
      </Box>
    </div>
  );
};

export default MobileNav;
