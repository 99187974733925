import bgImgHero from "../assets/bg_hero_image.jpg";
import { Box, Button, Typography } from "@mui/material";
import KidsplayHero from "./KidsplayHero";
import kidsImage1 from "../assets/kidplayground.jpg";
import kidsImage2 from "../assets/kidplayground2.jpg";
import kidsImage3 from "../assets/kidplayground3.jpg";
import kidsImage4 from "../assets/kidplayground4.jpg";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <div>
      <Box
        sx={{
          position: "relative",
          height: "100vh",
          width: "100%",
          overflow: "hidden",
        }}>
        <img
          src={bgImgHero}
          alt="Background Image"
          style={{
            objectFit: "cover",
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        />
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(45, 97, 106, 0.8)",
          }}
        />

        <KidsplayHero
          width="200px"
          height="200px"
          url={kidsImage1}
          top="25%"
          left="5%"
          animate="left"
        />

        <KidsplayHero
          width="150px"
          height="150px"
          url={kidsImage2}
          top="35%"
          left="80%"
          animate="right"
        />

        <KidsplayHero
          width="120px"
          height="120px"
          url={kidsImage3}
          top="65%"
          left="15%"
          animate="left"
        />

        <KidsplayHero
          width="80px"
          height="80px"
          url={kidsImage4}
          top="70%"
          left="76%"
          animate="right"
        />

        <Box
          sx={{
            width: { xl: "40%", lg: "40%", md: "40%", sm: "90%", xs: "90%" },
            position: "absolute",
            top: "55%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}>
          <Typography
            sx={{
              fontFamily: "Pacifico",
              color: "#FEE76D",
              fontSize: {
                xl: "51px",
                lg: "51px",
                md: "51px",
                sm: "30px",
                xs: "30px",
              },
            }}>
            Your Child's Health In Good Hands
          </Typography>
          <Typography
            sx={{
              color: "white",
              mt: "20px",
              textAlign: "center",
            }}>
            Your child's health is precious, and we provide expert care tailored
            to their unique needs. Our dedicated professionals ensure their
            well-being, happiness, and growth, creating a foundation for
            lifelong vitality
          </Typography>
          <Link to="/about-us">
            <Button
              sx={{
                color: "white",
                backgroundColor: "#FE6B6A",
                fontWeight: 500,
                padding: "15px 25px",
                borderRadius: "30px",
                mt: "40px",
                "&:hover": {
                  backgroundColor: "#FEE76D",
                  color: "#1B535D",
                },
              }}>
              Read More
            </Button>
          </Link>
        </Box>
      </Box>
    </div>
  );
};

export default Hero;
