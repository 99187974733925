import { Box, Typography } from "@mui/material";
import TestmonialsCarasouel from "./TestmonialsCarasouel";
import { forwardRef } from "react";

const Testimonials = forwardRef((props, ref) => {
  return (
    <div>
      <Box
        name="testimonials"
        ref={ref}
        sx={{
          backgroundColor: "#2D616A",
          textAlign: "center",
          padding: {
            xl: "30px",
            lg: "30px",
            md: "30px",
            sm: "20px",
            xs: "20px",
          },
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}>
        <Typography
          sx={{
            fontFamily: "Pacifico",
            color: "#FE6B6A",
          }}>
          Testimonials
        </Typography>
        <Typography
          sx={{
            fontFamily: "Pacifico",
            color: "#F1DF6D",
            fontSize: {
              xl: "41px",
              lg: "41px",
              md: "41px",
              sm: "30px",
              xs: "30px",
            },
            mb: "20px",
          }}>
          What They Are Saying
        </Typography>
        <TestmonialsCarasouel />
      </Box>
    </div>
  );
});

export default Testimonials;
