import { Box, Typography } from "@mui/material";
import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import logo from "../assets/logo.png";
import { Link, useNavigate } from "react-router-dom";

const Header = ({
  scrollToContact,
  setTestiScroll,
  scrollToTestimonial,
  setContactScroll,
}) => {
  const navigate = useNavigate();

  const HandleTesti = () => {
    setTestiScroll(true);
    scrollToTestimonial();
    navigate("/");
  };

  const HandleContact = () => {
    setContactScroll(true);
    scrollToContact();
    navigate("/");
  };
  const navLinkStyles = {
    fontSize: "15px",
    cursor: "pointer",
    color: "white",
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
      height: "3px",
      backgroundColor: "#f39c12",
      transform: "scaleX(0)",
      transformOrigin: "bottom top",
      transition: "transform 0.3s ease-in-out",
    },
    "&:hover::before": {
      transform: "scaleX(1)",
    },
    "&:hover": {
      transition: "transform 0.3s ease-in-out",
    },
  };

  const socialIcons = {
    fontSize: "15px",
    backgroundColor: "#FE6A6B",
    padding: "6px",
    borderRadius: "50%",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#FEE76D",
      color: "#1B535D",
    },
  };

  const handleclick = (link) => {
    window.open(link, "_blank");
  };

  return (
    <div>
      <Box
        sx={{
          display: {
            xl: "flex",
            lg: "flex",
            md: "flex",
            sm: "none",
            xs: "none",
          },
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
          color: "white",
          background: "transparent",
          position: "absolute",
          zIndex: 9999999,
          mt: "10px",
        }}>
        <Link to="/">
          <Box
            sx={{
              ml: "10px",
              width: "150px",
              height: "90px",
            }}>
            <img
              src={logo}
              alt="logo"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "fill",
              }}
            />
          </Box>
        </Link>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
            mr: "10px",
          }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "30px",
            }}>
            <Link
              to="/"
              style={{
                textDecoration: "none",
              }}>
              <Typography sx={navLinkStyles}>Home</Typography>
            </Link>
            <Link
              to="/about-us"
              style={{
                textDecoration: "none",
              }}>
              <Typography sx={navLinkStyles}>About Us</Typography>
            </Link>

            <Typography sx={navLinkStyles} onClick={HandleTesti}>
              Testimonials
            </Typography>
            <Link
              to="/blogs"
              style={{
                textDecoration: "none",
              }}>
              <Typography sx={navLinkStyles}>Parenting Blog's</Typography>
            </Link>
            <Typography sx={navLinkStyles} onClick={HandleContact}>
              Contact Us
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <FacebookIcon
              sx={socialIcons}
              onClick={() =>
                handleclick(
                  "https://m.facebook.com/profile.php/?id=100003089917869&name=xhp_nt__fb__action__open_user#_=_"
                )
              }
            />
            <InstagramIcon
              sx={socialIcons}
              onClick={() =>
                handleclick("https://www.instagram.com/drharshalpediatrician")
              }
            />
            <YouTubeIcon
              sx={socialIcons}
              onClick={() =>
                handleclick("https://www.youtube.com/@apladoctor5310")
              }
            />
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Header;
