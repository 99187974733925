import { Box, Typography } from "@mui/material";
import React from "react";
import qrCode from "../assets/qr_code.png";
import "./contactus.css";
import { forwardRef } from "react";
import { useState } from "react";

const Contactus = forwardRef((props, ref) => {
  const [userInfo, setUserInfo] = useState({});

  const HandleChange = (e) => {
    setUserInfo((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  function sendWhatsAppMessage(e) {}

  function sendWhatsAppMessage(e) {
    e.preventDefault();
    const message = `
      Name: ${userInfo.name},
      Age: ${userInfo.age},
      Sex: ${userInfo.sex},
      Weight: ${userInfo.weight},
      Message: ${userInfo.message}`;

    const whatsappLink = `https://wa.me/+918668926344/?text=${encodeURIComponent(
      message
    )}`;

    window.open(whatsappLink, "_blank");
  }

  return (
    <Box
      ref={ref}
      sx={{
        width: "100%",
        height: "auto",
        mt: "40px",
        mb: "25px",
      }}>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          textAlign: "center",
        }}>
        <Typography
          sx={{
            fontFamily: "Pacifico",
            color: "#FE6B6A",
          }}>
          Contact Us
        </Typography>
        <Typography
          sx={{
            fontFamily: "Pacifico",
            color: "#1A525D",
            fontSize: {
              xl: "41px",
              lg: "41px",
              md: "41px",
              sm: "30px",
              xs: "30px",
            },
            mb: "30px",
          }}>
          Book An Online Consultation
        </Typography>
        <Box
          sx={{
            width: "90%",
            height: "100%",
            m: "auto",
            display: "flex",
            // justifyContent: "space-between",
            alignItems: "center",
            flexDirection: {
              xl: "row",
              lg: "row",
              md: "row",
              sm: "column",
              xs: "column",
            },
            gap: "20px",
          }}>
          <Box
            sx={{
              width: {
                xl: "40%",
                lg: "40%",
                md: "40%",
                sm: "90%",
                xs: "90%",
              },
              height: {
                xl: "100%",
                lg: "100%",
                md: "100%",
                sm: "50%",
                xs: "50%",
              },
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              alignItems: "center",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              borderRadius: "10px",
              padding: "20px",
              backgroundColor: "#FE6B6A",
            }}>
            <img
              src={qrCode}
              alt="childWithParents"
              style={{
                width: "191px",
                height: "50%",
                objectFit: "contain",
              }}
            />
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                color: "white",
              }}>
              <span
                style={{
                  fontWeight: "bold",
                }}>
                UPI ID-
              </span>
              drharshal97@ibl
            </Typography>
            <Typography
              sx={{
                color: "white",
              }}>
              Scan the above QR code or use the above provided UPI ID to make a
              payment of 300 RS for online consultation. Upon submitting the
              form, you will be redirected to our WhatsApp account. Please send
              a screenshot or the details along with your submission.
            </Typography>
          </Box>
          <Box
            sx={{
              width: {
                xl: "58%",
                lg: "58%",
                md: "58%",
                sm: "90%",
                xs: "90%",
              },
              height: {
                xl: "100%",
                lg: "100%",
                md: "100%",
                sm: "50%",
                xs: "50%",
              },
              backgroundColor: "#FEE66C",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              borderRadius: "10px",
              padding: "20px",
            }}>
            <form
              onSubmit={sendWhatsAppMessage}
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "30px",
              }}>
              <input
                type="text"
                placeholder="Name"
                className="InputStyles"
                onChange={HandleChange}
                name="name"
                required
              />
              <input
                type="number"
                placeholder="Age"
                className="InputStyles"
                onChange={HandleChange}
                name="age"
                required
              />
              <select
                className="InputStyles"
                required
                placeholder="Sex"
                onChange={HandleChange}
                name="sex">
                <option value="" disabled selected style={{ color: "#757575" }}>
                  Sex
                </option>
                <option>Male</option>
                <option>Female</option>
              </select>
              <input
                type="text"
                placeholder="Weight"
                className="InputStyles"
                onChange={HandleChange}
                name="weight"
                required
              />
              <textarea
                cols="30"
                rows="10"
                placeholder="Message"
                required
                className="InputStyles"
                onChange={HandleChange}
                name="message"></textarea>

              <button className="btnForm">Send Message</button>
            </form>
          </Box>
        </Box>
      </Box>
    </Box>
  );
});

export default Contactus;
