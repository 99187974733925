import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Aboutus from "./pages/Aboutus";
import MobileNav from "./components/MobileNav";
import Header from "./components/Header";
import { useEffect, useRef, useState } from "react";
import Footer from "./components/Footer";
import Blogs from "./pages/Blogs";

function App() {
  const contactRef = useRef(null);
  const testimonailsRef = useRef(null);
  const [testiScroll, setTestiScroll] = useState(false);
  const [contactScroll, setContactScroll] = useState(false);

  const scrollToTestimonial = () => {
    if (testimonailsRef.current) {
      testimonailsRef.current.scrollIntoView({ behavior: "smooth" });
      setTestiScroll(false);
    }
  };
  const scrollToContact = () => {
    if (contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: "smooth" });
      setContactScroll(false);
    }
  };

  useEffect(() => {
    if (testiScroll) {
      scrollToTestimonial();
    }
    if (contactScroll) {
      scrollToContact();
    }
  }, [testiScroll, contactScroll]);

  function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
      if (pathname === "/about-us" || pathname === "/blogs") {
        window.scrollTo(0, 0);
      }
    }, [pathname]);

    return null;
  }

  return (
    <div className="App">
      <MobileNav
        scrollToContact={scrollToContact}
        scrollToTestimonial={scrollToTestimonial}
        setTestiScroll={setTestiScroll}
        setContactScroll={setContactScroll}
      />
      <Header
        scrollToContact={scrollToContact}
        scrollToTestimonial={scrollToTestimonial}
        setTestiScroll={setTestiScroll}
        setContactScroll={setContactScroll}
      />
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={
            <Home
              testimonailsRef={testimonailsRef}
              scrollToContact={scrollToContact}
              contactRef={contactRef}
            />
          }
        />
        <Route path="/about-us" element={<Aboutus />} />
        <Route path="/blogs" element={<Blogs />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
