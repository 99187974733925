import { useEffect } from "react";
import Hero from "../components/Hero";
import Section2 from "../components/Section2";
import { useState } from "react";
import Section3 from "../components/Section3";
import Section4 from "../components/Section4";
import Testimonials from "../components/Testimonials";
import Section5 from "../components/Section5";
import Contactus from "../components/Contactus";

function Home({ scrollToContact, testimonailsRef, contactRef }) {
  const [animateProgressBar, setAnimateProgressBar] = useState(false);

  // Function to check if the user has reached a specific section
  const checkScrollPosition = () => {
    const sectionElement = document.getElementById("section2"); // Replace with the ID of your section
    if (!sectionElement) return;

    const sectionRect = sectionElement.getBoundingClientRect();
    const windowHeight = window.innerHeight;

    // If the section is in the viewport, set animateProgressBar to true
    if (sectionRect.top < windowHeight) {
      setAnimateProgressBar(true);
    } else {
      setAnimateProgressBar(false);
    }
  };

  useEffect(() => {
    // Add scroll event listener to track scroll position
    window.addEventListener("scroll", checkScrollPosition);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", checkScrollPosition);
    };
  }, []);

  return (
    <div className="Home">
      <Hero />
      <div id="section2">
        <Section2 animateProgressBar={animateProgressBar} />
      </div>
      <Section3 scrollToContact={scrollToContact} />
      <Section4 />
      <Testimonials ref={testimonailsRef} />
      <Section5 />
      <Contactus ref={contactRef} />
    </div>
  );
}

export default Home;
