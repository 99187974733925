import { Box, Button, Typography } from "@mui/material";
import section3img from "../assets/section3.png";
import drmahale from "../assets/drmahalesm.png";

const Section3 = ({ scrollToContact }) => {
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          padding: {
            xl: "30px",
            lg: "30px",
            md: "30px",
            sm: "20px",
            xs: "20px",
          },
          //   padding: "30px",
          justifyContent: "space-between",
          alignItems: "flex-start",
          backgroundColor: "#2D616A",
          flexDirection: {
            xl: "row",
            lg: "row",
            md: "row",
            sm: "column",
            xs: "column",
          },
          gap: "20px",
        }}>
        <Box
          position="relative"
          sx={{
            width: { xl: "48%", lg: "48%", md: "48%", sm: "100%", xs: "100%" },
          }}>
          <Box
            sx={{
              position: "absolute",
              textAlign: "left",
              borderRadius: "20px",
              top: "2%",
              right: "-4%",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              alignItems: "flex-end",
            }}>
            <Typography
              sx={{
                backgroundColor: "rgba(76, 139, 229, 0.9)",
                padding: "10px",
                borderRadius: "0px 20px 20px 20px",
                width: "235px",
                color: "white",
                fontWeight: 500,
              }}>
              Hi doc, I wanna ask something..
            </Typography>
            <Typography
              sx={{
                backgroundColor: "rgba(109, 201, 231, 0.9)",
                padding: "10px",
                borderRadius: "20px 0px 20px 20px",
                width: "215px",
                color: "white",
                fontWeight: 500,
              }}>
              Hello, How can I assist you ?
            </Typography>
            <Typography
              sx={{
                backgroundColor: "rgba(76, 139, 229, 0.9)",
                borderRadius: "0px 20px 20px 20px",
                width: "50px",
                color: "white",
                fontWeight: "bold",
                padding: "10px",
                alignSelf: "flex-start",
              }}>
              ...
            </Typography>
          </Box>
          <Box
            sx={{
              position: "absolute",
              backgroundColor: "white",
              width: "250px",
              textAlign: "left",
              borderRadius: "20px",
              bottom: "27%",
              left: "5%",
            }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
                padding: "20px",
              }}>
              <img
                src={drmahale}
                style={{
                  width: "80px",
                  height: "70px",
                  borderRadius: "50%",
                  backgroundColor: "gray",
                }}
                alt="drmahale"
              />
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                  }}>
                  Dr. Harshal Mahale
                </Typography>
                <Typography color="gray">A pediatrician</Typography>
              </Box>
            </Box>
            <Button
              onClick={scrollToContact}
              sx={{
                width: "100%",
                color: "white",
                backgroundColor: "#6CB1B8",
                borderRadius: "0px 0px 20px 20px",
                "&:hover": {
                  backgroundColor: "#6CB1B8",
                  color: "white",
                },
              }}>
              SEND A MESSAGE
            </Button>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: {
                xl: "120vh",
                lg: "120vh",
                md: "120vh",
                sm: "160vh",
                xs: "110vh",
              },
            }}>
            <img
              src={section3img}
              alt="section3img"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "fill",
                borderTopLeftRadius: "150px",
                borderBottomLeftRadius: "350px",
                borderBottomRightRadius: "350px",
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: { xl: "48%", lg: "48%", md: "48%", sm: "100%", xs: "100%" },
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            mt: "50px",
          }}>
          <Typography
            sx={{
              fontFamily: "Pacifico",
              color: "#F1DF6D",
              fontSize: {
                xl: "51px",
                lg: "51px",
                md: "51px",
                sm: "30px",
                xs: "30px",
              },
            }}>
            SAME-DAY
          </Typography>
          <Typography color="white" fontSize="27px" mt="-10px">
            Emergency Appointment!
          </Typography>
          <Typography
            sx={{
              color: "white",
              fontSize: "20px",
            }}>
            Be sure, that however acute the injury or the illness is, we will be
            there to help you child, 24/7/365!
          </Typography>
          <Button
            onClick={scrollToContact}
            sx={{
              color: "white",
              backgroundColor: "#FE6B6A",
              fontWeight: 500,
              padding: "15px 25px",
              borderRadius: "5px",
              mt: "40px",
              "&:hover": {
                backgroundColor: "#FEE76D",
                color: "#1B535D",
              },
            }}>
            Schedule Today
          </Button>
          {/* <AccordionComponent /> */}
        </Box>
      </Box>
    </div>
  );
};

export default Section3;
