import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import parent1 from "../assets/parent1.jpg";
import parent2 from "../assets/parent2.jpg";
import parent3 from "../assets/parent3.jpg";
import parent4 from "../assets/parent4.jpg";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper/modules";
import { Box, Typography } from "@mui/material";

export default function TestmonialsCarasouel() {
  const [swiperRef, setSwiperRef] = useState(null);

  const testimonialsData = [
    {
      name: "Rashmi bhavsar",
      imgUrl: parent1,
      tag: "Parent",
      comment:
        "Dr.Harshal has been a blessing for our family. His expertise, patience, and warm demeanor make every visit a pleasant experience. Our children are in great hands with him !",
    },

    {
      name: "Harshali Aher",
      imgUrl: parent2,
      tag: "Parent",
      comment:
        "We can't thank Dr.Harshal enough for his exceptional care. His ability to connect with kids and his willingness to answer all our questions have made him our go-to pediatrician. We highly recommend him.",
    },
    {
      name: "Sheetal Patil",
      imgUrl: parent3,
      tag: "Parent",
      comment:
        "Dr. Harshal is more than just a doctor; he's a partner in our child's health journey. His proactive approach to preventive care has kept our little one healthy and happy. We are so grateful for his dedication",
    },
    {
      name: "Shivani Patil",
      imgUrl: parent4,
      tag: "Parent",
      comment:
        "Dr. Harshal has been our family's trusted pediatrician for years. His compassion, deep knowledge, and genuine concern for our kids' well-being make him a true standout in his field.",
    },
  ];

  return (
    <>
      <Swiper
        style={{ width: "100%" }}
        onSwiper={setSwiperRef}
        breakpoints={{
          // when window width is >= 640px
          300: {
            width: 300,
            slidesPerView: 1,
          },
          // when window width is >= 768px
          768: {
            width: 768,
            slidesPerView: 2,
          },
          1023: {
            width: 1024,
            slidesPerView: 3,
          },
        }}
        spaceBetween={30}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper">
        {testimonialsData?.map((el, index) => (
          <SwiperSlide key={index}>
            <Box
              sx={{
                backgroundColor: "#FE6B6A",
                padding: "20px",
                color: "white",
                ml: "20px",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                borderRadius: "10px",
                height: "300px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}>
              <Typography>{el.comment}</Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}>
                <img
                  src={el.imgUrl}
                  alt={el.name}
                  style={{
                    width: "80px",
                    height: "80px",
                    borderRadius: "50%",
                  }}
                />
                <Box>
                  <Typography
                    sx={{
                      color: "#F1DF6D",
                      fontFamily: "Pacifico",
                      textAlign: "left",
                    }}>
                    {el.name}
                  </Typography>
                  <Typography
                    sx={{
                      textAlign: "left",
                    }}>
                    {el.tag}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
