import { Box, Typography } from "@mui/material";
import React from "react";
import drmahale from "../assets/drmahale.png";

const Section4 = () => {
  return (
    <div>
      <Box
        sx={{
          textAlign: "center",
          padding: {
            xl: "30px",
            lg: "30px",
            md: "30px",
            sm: "20px",
            xs: "20px",
          },
        }}>
        <Typography
          sx={{
            fontFamily: "Pacifico",
            color: "#FE6B6A",
          }}>
          The Best Doctor
        </Typography>
        <Typography
          sx={{
            fontFamily: "Pacifico",
            color: "#1A525D",
            fontSize: {
              xl: "41px",
              lg: "41px",
              md: "41px",
              sm: "30px",
              xs: "30px",
            },
          }}>
          Meet Our Specialist
        </Typography>
        <Box
          sx={{
            height: {
              xl: "100vh",
              lg: "100vh",
              md: "100vh",
              sm: "100vh",
              xs: "80vh",
            },
            width: { xl: "35%", lg: "35%", md: "40%", sm: "50%", xs: "84%" },
            margin: "auto",
            mt: "30px",
            borderRadius: "10px",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            padding: "20px",
            backgroundColor: "white",
          }}>
          <img
            src={drmahale}
            alt="drmahale"
            style={{
              width: "100%",
              height: "80%",
              objectFit: "fill",
            }}
          />
          <Box
            sx={{
              mt: "15px",
            }}>
            <Typography
              sx={{
                fontFamily: "Pacifico",
                color: "#1A525D",
                fontSize: "20px",
              }}>
              Dr. Harshal Mahale
            </Typography>
            <Typography
              sx={{
                color: "#FE6B6A",
              }}>
              Pediatrician
            </Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Section4;
