import React from "react";
import { Box, Typography } from "@mui/material";
import BgImageBlogs from "../assets/parenting_blogs_bg.jpg";
import CircleIcon from "@mui/icons-material/Circle";

const Blogs = () => {
  const blogsData = [
    {
      heading: "1.Healthy Sleep and Bedtime Routines for Children",
      subheading:
        "  As a pediatrician, I have witnessed firsthand the importance of sleep in a child's development and overall well-being. Sleep is not merely a necessity; it's a fundamental building block for a child's physical, cognitive, and emotional growth. Establishing a healthybedtime routine is key to ensuring your child gets the sleep theyneed. In this blog, we will explore the significance of sleep in pediatric care and offer practical advice on creating an effective  bedtime routine for your child.",
      points: [
        {
          heading: "The Importance of Sleep in Childhood",
          subheadings: [
            " Quality sleep plays a pivotal role in a child's life. Here are some of the reasons why sleep is crucial for children.",
            " Physical Growth: During sleep, the body releases growth hormones that are essential for a child's physical development. This is particularly important in the early years of life.",
            " Cognitive Development: Sleep is crucial for memory consolidation, problem-solving, and learning. Children who sleep well tend to  perform better academically.",
            "Emotional Regulation: A well-rested child is better equipped to manage their emotions, reducing the risk of mood swings, temper tantrums, and behavioral problems.",
            " Immune System: Sleep strengthens the immune system, helping children ward off illnesses and recover faster when they do get sick.",
            " Overall Health: Insufficient sleep in childhood has been linked to an increased risk of obesity, diabetes, and other health issues.",
          ],
        },
        {
          heading: "Establishing a Bedtime Routine",
          subheadings: [
            "Now that we understand the importance of sleep, let's delve into creating a bedtime routine that fosters healthy sleep habits in children.",
            "Consistency is Key: Try to establish a consistent sleep schedule. This means having a set bedtime and wake-up time, even on weekends. Consistency helps regulate the body's internal clock.",
            "Create a Relaxing Environment: Make the bedroom conducive to sleep. This includes a comfortable mattress and bedding, a dark and cool room, and white noise if needed. Remove stimulating distractions like screens from the bedroom.",
            "Wind Down: Start winding down at least 30 minutes before bedtime. This could involve calming activities such as reading a book, taking a warm bath, or practicing deep breathing exercises.",
            "Limit Stimulants: Avoid giving your child caffeine or sugary snacks close to bedtime. These can interfere with sleep.",
            "Set Boundaries: Be firm about bedtime rules. Let your child know the importance of sleep and the consequences of not getting enough rest.",
            "Be a Role Model: Children often learn by example. Show them that you prioritize sleep by maintaining a healthy sleep routine yourself.",
            "Monitor Sleep Quality: Pay attention to your child's sleep patterns and adjust the routine as needed. If they consistently have trouble sleeping, consult a pediatrician.",
            "As a pediatrician, I cannot overemphasize the significance of sleep in childhood. A well-established bedtime routine is a powerful tool for ensuring your child gets the sleep they need for growth and development. By following these guidelines and prioritizing sleep, you can set your child on a path to a healthier, happier, and more successful future. Sweet dreams!",
          ],
        },
      ],
    },
    {
      heading: "2.Immunization and Preventive Care",
      subheading:
        "As a pediatrician, my primary goal is to ensure the health and well-being of children. One of the most effective ways to achieve this is through immunization and preventive care. In this blog, we will explore the critical role immunizations play in safeguarding children's health and discuss the importance of comprehensive preventive care.",
      points: [
        {
          heading: "Immunization - Shielding Kids from Serious Diseases",
          subheadings: [
            "Immunizations, also known as vaccines, are among the most significant medical advances in history. They work by stimulating a child's immune system to produce an immune response, without causing the disease itself. Here are some key reasons why immunizations are crucial.",
            "Disease Prevention: Immunizations protect children from potentially life-threatening diseases such as measles, mumps, rubella, polio, and whooping cough. They also guard against severe complications like pneumonia and meningitis.",
            "Community Immunity: Vaccination contributes to herd immunity, which occurs when a high percentage of the population is immunized, making it difficult for diseases to spread. This helps protect those who cannot be vaccinated due to medical reasons, like allergies or immunodeficiency.",
            "Safe and Effective: Vaccines undergo rigorous testing for safety and efficacy before approval. They are continuously monitored for any adverse effects, ensuring their safety.",
          ],
        },
        {
          heading: "The Immunization Schedule",
          subheadings: [
            " As pediatricians, we follow a recommended immunization schedule established by organizations like the Centers for Disease Control and Prevention (CDC) and the World Health Organization (WHO). This schedule outlines when children should receive specific vaccines and booster shots.",
            "Some vaccines, like the MMR (measles, mumps, rubella) vaccine, require two doses for full protection. It is essential to adhere to this schedule to ensure children receive vaccinations at the right time to maximize their effectiveness",
          ],
        },
        {
          heading: "Preventive Care Beyond Vaccination ",
          subheadings: [
            "While immunizations are a vital component of preventive care, they are not the only aspect. Pediatricians emphasize a holistic approach to keep children healthy. Here are some additional aspects of preventive care",
            "Well-Child Visits: Regular check-ups with your pediatrician are crucial for monitoring your child's growth and development, addressing concerns, and ensuring they receive age-appropriate screenings and vaccinations.",
            "Nutrition and Diet: Proper nutrition is essential for a child's growth and development. Pediatricians offer guidance on nutrition and can address concerns related to diet, allergies, or weight management.",
            "Behavioral Health: Identifying and addressing behavioral and mental health issues early is essential. Pediatricians can provide guidance on managing issues like anxiety, depression, and ADHD. ",
            "Safety Education: Preventive care also involves educating parents and children about safety measures, such as childproofing the home, car seat safety, and safe play practices.",
            "As a pediatrician, my mission is to provide the best possible care to children, and immunization and preventive care are fundamental to achieving that goal. Immunizations protect children from serious diseases, while comprehensive preventive care encompasses all aspects of a child's well-being, from physical health to emotional and social development.",
            "By following recommended immunization schedules and partnering with your pediatrician for regular check-ups and guidance, we can ensure that every child has the opportunity to grow up healthy and thrive in a safe and nurturing environment. Together, we can build a healthier future for our children.",
          ],
        },
      ],
    },
    {
      heading: "3.Screen Time and Children",
      subheading:
        "As a pediatrician, my primary concern is the well-being and healthy development of children. In today's digital age, one of the most pressing issues we face is the amount of time children spend in front of screens. Screen time encompasses television, computers, tablets, smartphones, and video games. While these devices have become an integral part of our lives, excessive screen time can have profound effects on children's physical, mental, and emotional health. In this blog, we will explore the impact of screen time on children and offer practical recommendations for parents to strike a healthy balance.",
      points: [
        {
          heading: "The Risks of Excessive Screen Time.",
          subheadings: [
            "Physical Health Issues: Excessive screen time often leads to a sedentary lifestyle, contributing to health problems such as obesity and poor posture. Children who spend more time in front of screens are less likely to engage in physical activities that are essential for their growth and development.",
            "Sleep Disruption: The use of screens before bedtime can interfere with a child's sleep patterns. The blue light emitted by screens can disrupt the production of melatonin, a hormone that regulates sleep. This can result in difficulty falling asleep and poor-quality sleep.",
            "Mental Health Concerns: There is growing evidence linking excessive screen time to mental health issues in children, including increased rates of anxiety, depression, and attention problems. Social media and online content can expose children to cyberbullying and unrealistic body image standards, contributing to self-esteem issues.",
            "Reduced Academic Performance: Excessive screen time can also impact a child's academic performance. Too much time spent on screens can lead to decreased focus and concentration, hindering their ability to learn and succeed in school. ",
            "Behavioral Problems: Prolonged exposure to screens can lead to behavioral problems, such as increased aggression and irritability. It can also hinder a child's ability to develop essential social skills through face-to-face interactions.",
          ],
        },
        {
          heading: "Setting Healthy Screen Time Limits",
          subheadings: [
            "As a pediatrician, I often advise parents on setting healthy screen time limits for their children. Here are some practical recommendations.",
            "Create Screen-Free Zones: Designate specific areas of the home, such as the dining room and bedrooms, as screen-free zones to promote family interaction and better sleep. ",
            "Set Time Limits: Establish daily and weekly screen time limits based on your child's age and developmental stage. The American Academy of Pediatrics recommends no more than one hour of high-quality screen time for children aged 2 to 5.",
            "Encourage Outdoor Play: Encourage outdoor activities and physical play to promote a healthy, active lifestyle. Limit screen time until after homework and outdoor playtime. ",
            "Co-View and Discuss Content: When children do watch screens, co-view with them and discuss the content. Ensure that the content is age-appropriate and educational.",
            "Model Healthy Screen Use: Children learn by example. Be a role model by practicing healthy screen use and demonstrating the importance of balance.",
            "As a pediatrician, I cannot emphasize enough the importance of managing screen time for children. While screens can be valuable tools for learning and entertainment, excessive use can lead to a range of physical, mental, and emotional health issues. It is crucial for parents to take an active role in monitoring and setting healthy screen time limits for their children. By doing so, we can help ensure that children grow and develop in a balanced and healthy way in this digital age.",
          ],
        },
      ],
    },
    // {
    //     heading: "",
    //     subheading: "",
    //     points: [
    //       {
    //         heading: "",
    //         subheadings: [],
    //       },
    //     ],
    //   },
  ];

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "30px",
          mb: "50px",
          textAlign: "left",
        }}>
        <Box
          sx={{
            borderRadius: "0 0 240px 50%/35px",
            position: "relative",
            height: "80vh",
            width: "100%",
            overflow: "hidden",
          }}>
          <img
            src={BgImageBlogs}
            alt="Background Image"
            style={{
              borderRadius: "0 0 240px 50%/35px",
              objectFit: "cover",
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
            }}
          />
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background: "rgba(45, 97, 106, 0.8)",
            }}
          />
          <Typography
            sx={{
              position: "absolute",
              color: "#fff",
              fontSize: "3rem",
              fontWeight: 700,
              fontFamily: "Pacifico",
              top: "40vh",
              left: "20px",
              textAlign: "left",
            }}>
            Parenting Blog's
          </Typography>
        </Box>
        {blogsData?.map((el, index) => (
          <Box
            key={index}
            sx={{
              padding: "25px",
              display: "flex",
              flexDirection: "column",
              gap: "15px",
            }}>
            <Typography
              sx={{
                fontFamily: "Pacifico",
                color: "#1A525D",
                fontSize: {
                  xl: "41px",
                  lg: "41px",
                  md: "41px",
                  sm: "30px",
                  xs: "30px",
                },
              }}>
              {el.heading}
            </Typography>
            <Typography
              sx={{
                color: "#888A88",
              }}>
              {el.subheading}
            </Typography>
            {el.points.map((elemPoints, index) => (
              <Box key={`${index + index * index}${elemPoints}`}>
                <Typography
                  sx={{
                    fontFamily: "Pacifico",
                    color: "#FE6B6A",
                    fontSize: {
                      xl: "21px",
                      lg: "21px",
                      md: "21px",
                      sm: "17px",
                      xs: "17px",
                    },
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    mb: "10px",
                  }}>
                  <CircleIcon sx={{ fontSize: "10px" }} /> {elemPoints.heading}
                </Typography>
                {elemPoints.subheadings.map((elPointsSub, index) => (
                  <Typography
                    key={`${index + index * index}${elPointsSub}`}
                    sx={{
                      color: "#888A88",
                    }}>
                    {elPointsSub}
                    <br />
                    <br />
                  </Typography>
                ))}
              </Box>
            ))}
          </Box>
        ))}
      </Box>
    </div>
  );
};

export default Blogs;
