import { Box } from "@mui/material";
const KidsplayHero = ({ width, height, url, top, left, animate }) => {
  return (
    <Box
      sx={{
        display: {
          xl: "block",
          lg: "block",
          md: "block",
          sm: "none",
          xs: "none",
        },
        width: width,
        height: height,
        position: "absolute",
        top: top,
        left: left,
      }}>
      <img
        src={url}
        alt="kids"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "fill",
          borderRadius: "50%",
        }}
      />
    </Box>
  );
};

export default KidsplayHero;
